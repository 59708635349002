import React, { FunctionComponent, useState } from "react"
import { navigate } from "gatsby"
import Heading from "~/components/configurable/Heading"
import BlockCTA from "~/components/configurable/BlockCTA"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { window } from 'global';

import "./styles.scss"
import { SPECIAL_PRICE_KEY, SPECIAL_PRICE_VALUE } from "~/components/pages/quote"


const HERT_PAGE = 'hert_collective';

const Quote: FunctionComponent<any> = ({
  title = "Get a quote today.",
  description = "It only takes 2 minutes to request a no-obligation quote, customised to you and your home's needs. No sales visit required!",
  ctaText = "Request Quote",
  compact = false,
}) => {

  const path = window?.location?.pathname.replace(/\//g, '');

  const urlParams = new URLSearchParams(window?.location?.search || ''); 

  const formState = {
    // If this the `/hert_collective` could have queryParams change this to includes
    isHert:path === HERT_PAGE ? 'Yes' : 'No',
    isShortQuote: 'Yes',
    isSpecialPrice: urlParams.get(SPECIAL_PRICE_KEY) === SPECIAL_PRICE_VALUE ? 'Yes' : 'No'
  }

  const handleInputChange = event => {
    formState[event.target.name] = event.target.value
  }

  const handleSubmit = event => {
    event.preventDefault()

    const eventData = {
      category: "Form",
      action: "Submit",
      label: "ShortQuote",
      // value: 0 // optional
    }
    

    trackCustomEvent(eventData)

    if (window) {
      window.dataLayer = window?.dataLayer || []
    }

    return navigate("/quote", {
      state: formState,
    })
  }

  return (
    <div className="quote">
      <Heading level={2}>{title}</Heading>
      <p>{description}</p>
      <form
        className="form form--horizontal"
        name="quote-block"
        onSubmit={handleSubmit}
      >
        <div className="form__inner">
          <div className="form__fields">
            <input
              className="form__text-input"
              type="text"
              placeholder="Full name"
              name="name"
              onChange={handleInputChange}
              required
            />
            <input
              className="form__text-input"
              type="email"
              placeholder="Email"
              name="email"
              onChange={handleInputChange}
              required
            />
            <input
              className="form__text-input"
              type="tel"
              placeholder="Phone"
              name="phone"
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form__actions">
            <BlockCTA submit inline>
              {ctaText}
            </BlockCTA>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Quote
